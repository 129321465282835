<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-12">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Gerenciar Atendimientos</h3>
        </div>

        <div class="card-body">
          <div class="row form-group justify-content-center">
            <div class="form-group col-md-3">
              <label class="col-md-12">Paciente :</label>
              <input required type="text" class="form-control" v-model="filtro.paciente" @keyup.enter="pesquisar()" />
            </div>
            <div class="form-group col-md-3">
              <label class="col-md-12">{{ $t("DATAINICIO.NOME") }}</label>
              <input required type="date" class="form-control" v-model="filtro.data_inicio" />
            </div>
            <div class="form-group col-md-3">
              <label class="col-md-12">{{ $t("DATAFINAL.NOME") }}</label>
              <input required type="date" class="form-control" v-model="filtro.data_fim" />
            </div>
            <div class="form-group col-md-2">
              <label class="col-md-12">Status</label>
              <select required type="text" class="form-control" v-model="filtro.status">
                <option :value="null">Todos</option>
                <!-- <option value="0">Excluido </option> -->
                <option value="1">Abierto </option>
                <option value="2">Cerrado </option>
                <option value="3">Aguardando Triaje </option>
                <option value="4">Aguardando Consulta </option>
                <option value="5">Consulta Hecha </option>
              </select>
            </div>
            <div class="col-md-1 mt-8">
              <div class="">
                <button class="btn btn-success" @click="pesquisar()">
                  <i class="fa fa-search search-icon m-0 p-0"></i>
                </button>
              </div>
              <!-- <div class="ml-1">
                <button class="btn btn-info" @click="gerarExcel()">
                  <i class="fa fa-print m-0 p-0"></i>
                </button>
              </div> -->
            </div>
          </div>
          <div class="row" style="align-items: center">
            <b-table :fields="[
              'data',
              'paciente',
              'rg',
              'consultorio',
              'medico',
              // 'tipo',
              'status',
              'acoes',
            ]" :items="lista_atendimentos" :per-page="perPage" :current-page="currentPage" id="atendimento-table"
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
              " show-empty empty-text="Nenhum registro encontrado!">
              <template #head(acoes)><span></span></template>
              <template #head(data)>Fecha</template>
              <template #cell(acoes)="{ item }">
                <div class="text-right w-100">
                  <!-- <b-dropdown right split text="Split Menu">
                    <b-dropdown-item>
                      <i class="far fa-edit text-primary"></i>
                      Editar</b-dropdown-item
                    >
                    <b-dropdown-item>
                      <i class="far fa-eye text-success"></i>
                      Visualizar</b-dropdown-item
                    >
                    <b-dropdown-item>
                      <i
                        v-if="item.status == 1"
                        class="fas fa-unlock-alt text-info"
                      ></i
                    >    Bloquear</b-dropdown-item>
                  </b-dropdown> -->
                  <button v-if="item.status == 3 || item.status == 4" v-show="lista_permissoes_filial.u_Atendimento"
                    @click="chamar_paciente(item)" class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover
                    title="Llamar en la pantalha de turnos">
                    <i class="fas fa-volume-up text-warning"></i>
                  </button>

                  <button v-if="item.status != 2" v-show="lista_permissoes_filial.u_Atendimento"
                    @click="atualizar(item)" class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover
                    title="Editar registro">
                    <i class="far fa-edit text-primary"></i>
                  </button>


                  <button v-show="lista_permissoes_filial.r_Atendimento" @click="trocar_pagina(item.id)"
                    class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Atendimento">
                    <i class="far fa-eye text-success"></i>
                  </button>
                  <button v-show="lista_permissoes_filial.lock_Atendimento" @click="
                    confirm(item, 'FINALIZARA el atendimiento del sistema')
                    " class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Encerrar/Reabrir registro">

                    <i v-if="item.status != 2" class="fas fa-lock text-info"></i>
                    <i v-if="item.status == 2" class="fas fa-unlock-alt text-info"></i>
                  </button>
                  <button v-show="lista_permissoes_filial.d_Atendimento" @click="
                    confirm(
                      item,
                      'ELIMINARA el atendimiento del sistema. Este no contabilizara en los relatorios, tampoco en el historico del paciente'
                    )
                    " class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Excluir registro">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-trash text-danger" viewBox="0 0 16 16">
                      <path
                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                      <path fill-rule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                    </svg>
                  </button>
                </div>
              </template>
              <template #cell(tipo)="{ item }"><span></span>
                <span style="font-size: 12px" class="label label-lg label-inline" :class="{
                  'label-light-success': item.tipo === 1,
                  'label-light-info': item.tipo === 2,

                }">
                  {{
                    item.tipo === 1
                      ? "Consulta"
                      : item.tipo === 2
                        ? "Retorno"
                        : ""
                  }}
                </span>
              </template>
              <template #cell(status)="{ item }"><span></span>
                <span style="font-size: 12px" class="label label-lg label-inline" :class="{
                  'label-light-warning': item.status === 1,
                  'label-light-success': item.status === 2,
                  'label-light-info': item.status === 3,
                  'label-light-info': item.status === 4,
                  'label-light-primary': item.status === 5,
                }">
                  {{
                    item.status === 1
                      ? "Abierto"
                      : item.status === 2
                        ? "Cerrado"
                        : item.status === 3
                          ? "Aguardando Triaje"
                          : item.status === 4
                            ? "Aguardando Consulta"
                            : item.status === 5
                              ? "Consulta Hecha"
                              : ""
                  }}
                </span>
              </template>
            </b-table>
          </div>

          <b-pagination v-model="currentPage" :total-rows="lista_atendimentos.length" :per-page="perPage"
            aria-controls="atendimento-table">
          </b-pagination>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import { getFilialId } from "../../../core/services/filial.service";
export default {
  mixins: [fireAlert],
  data() {
    return {
      removido: [],
      currentPage: 1,
      perPage: 10,
      filtro: {
        data_inicio: null,
        data_fim: null,
        paciente: null,
        status: null,
        medico_id: null,
      },
      // texto:
    };
  },
  // watch: {
  //   filtro(){
  //     console.log(3)
  //   }
  // },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Atendimiento" }]);
  },
  created() {
    this.filtro.data_inicio = this.obterDataAtual()
    this.listar_atendimentos();
  },
  computed: {
    lista_atendimentos() {
      return this.$store.state.atendimento.lista_atendimentos;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.atendimento.mensagem_alert;
    },
  },
  methods: {
    obterDataAtual() {
      const dataAtual = new Date();
      const ano = dataAtual.getFullYear();
      const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Os meses são indexados a partir de 0
      const dia = String(dataAtual.getDate()).padStart(2, '0');
      return `${ano}-${mes}-${dia}`;
    },
    async chamar_paciente(item) {
      console.log(item)
      let consultorio = "Consultorio"
      if (item.status == 3) {
        consultorio = "Triaje"
      } else if (item.consultorio != null) {
        consultorio = item.consultorio
      }
      const axios = require('axios');
      let data = JSON.stringify({
        "id": item.usuario_id,
        "status": 1,
        "tela_id": getFilialId(),
        "nome_sala": consultorio
      });

      console.log(data)

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://fila.pagwise.com/api/chamar-paciente',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

    },
    async listar_atendimentos() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("atendimento/listar_atendimentos")
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          this.$store.dispatch("configEmpresa/atualizar", "");
        });
    },
    atualizar(value) {
      this.$router.push({ name: "createAtendimento" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async pesquisar() {
      this.filtro.medico_id = null
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch(
        "atendimento/pesquisar_atendimentos",
        this.filtro
      );
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async confirm(value, text) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta accion ` + text,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (
              text ==
              "ELIMINARA el atendimiento del sistema. Este no contabilizara en los relatorios, tampoco en el historico del paciente"
            )
              this.bloquear(value);
            if (text == "FINALIZARA el atendimiento del sistema")
              this.finalizar(value);
          }
        },
      });
    },
    async bloquear(value) {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("atendimento/bloquear_atendimento", value)

      this.$store.dispatch("configEmpresa/MudarPreloader", false);
      await this.listar_atendimentos()
      this.fireAlert(this.mensagem_alert);
    },
    async finalizar(value) {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("atendimento/finalizar_atendimento", value);
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
      this.listar_atendimentos();
      this.fireAlert(this.mensagem_alert);
    },
    async trocar_pagina(id) {
      this.$router.push({
        name: "createOcorrencia",
        params: { atendimento_id: id },
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>